@import "theme";
@import "../../src/app/shared/services/global-drop-zone/global-drop-zone.scss";

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;

  app-root {
    height: 100%;
  }

  /* overrides:tooltip */
  mat-tooltip-component {
    max-width: 250px;

    .mat-tooltip {
      padding: 4px 7px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      background: black(87);
      border-radius: 3px;
      word-break: break-word;
    }
  }

  .card-tooltip {
    margin: 0px;
  }

  .mat-tooltip-panel-above:has(.card-tooltip) {
    transform: translateY(-12px);
  }

  .mat-tooltip-panel-below:has(.card-tooltip) {
    transform: translateY(12px);
  }

  /* overrides:dialog */
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
  }

  .cdk-overlay-dark-backdrop {
    background: black(87);
  }

  .cdk-overlay-pane.no-scroll {
    & > .mat-dialog-container {
      position: relative;
      overflow: visible;
    }
  }

  /* overrides:.mat-form-field */
  .mat-form-field {
    width: auto;
  }

  .mat-form-field input.mat-input-element {
    height: 1.125em; // this one enforces consistent line height in input[type=text|time|password|etc]
  }

  .mat-form-field-appearance-standard.mat-form-field-has-label
    .mat-select:not(.mat-select-empty)
    .mat-select-arrow-wrapper {
    transform: unset;
  }

  .mat-select-panel,
  .mat-autocomplete-panel {
    max-height: 312px;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    font-size: 12px;
    font-weight: 400;
    color: black(54);

    // TODO: possibly extend for every button/icon inside infix/suffix
    .mat-datepicker-toggle {
      & > button {
        bottom: -3px;
      }

      &:not(.mat-datepicker-toggle-active) {
        color: black(87);
      }
    }
  }

  .tooltip-svg {
    height: 132px;
    width: 100%;
    padding: 0 18px;
    box-sizing: border-box;
    .primary-color-fill {
      fill: primary();
    }
    .primary-color-stroke {
      stroke: primary();
    }
  }

  /* overrides:.mat-input-element */
  .mat-form-field.mat-primary .mat-input-element {
    caret-color: primary();
  }

  .mat-form-field.mat-accent .mat-input-element {
    caret-color: accent();
  }

  /* overrides:button <button mat-button>...</button> */
  .mat-button,
  .mat-raised-button,
  .mat-stroked-button,
  .mat-flat-button {
    min-width: unset;
    line-height: 40px;
    text-transform: uppercase;
  }

  /* overrides:button <button mat-flat-button>...</button> */
  .mat-flat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-mini-fab {
    color: greyText();
  }

  /* overrides:datepicker */
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }

  /* overrides:card */
  .mat-card {
    margin: 24px 0;
    padding: 22px;

    .mat-card-title {
      line-height: 28px;
      font-size: 24px;
      font-weight: 300;
    }

    .mat-card-content {
      margin-bottom: 0;
    }

    & > .mat-card-actions:last-child {
      margin: 0px -4px 0px;

      & > * {
        margin: 0 4px;
      }
    }
  }
  @media (max-width: 600px) {
    .mat-card {
      margin: 24px 0;
      padding: 22px;
    }
  }

  /* overrides:radio <mat-radio-button>...</mat-radio-button> */
  .mat-radio-button {
    margin-right: 18px;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 16px;
    }

    &:not(.mat-radio-disabled) {
      &.mat-accent.mat-radio-checked,
      & {
        .mat-radio-outer-circle {
          border-color: black(87);
        }
      }

      .mat-radio-label-content {
        color: black(87);
      }
    }

    .mat-radio-label-content {
      font-weight: 400;
    }
  }

  /* overrides:checkbox */
  .mat-checkbox {
    .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }

    .mat-checkbox-checkmark-path {
      stroke: accentText() !important;
      stroke-width: 2.5px;
    }

    .mat-checkbox-label {
      font-weight: 400;
    }
  }

  /* router link <a router-link="..." color="...">...</a> */
  a[ng-reflect-router-link] {
    text-decoration: none;

    &[color="primary"]:not(.mat-flat-button) {
      color: primary();
    }
  }

  /* errors like material <span class="app-hint-message" color="warn">...</span> */
  .app-hint-message {
    font-size: 12px;

    &[color="warn"] {
      color: warn();
    }
  }

  /* globals */
  .flex {
    display: flex;
    flex: 1;
  }

  .tooltip-multiline {
    white-space: pre-wrap;
  }

  .app-cursor-tracker-tooltip {
    position: absolute;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    padding: 3px 8px;
    border-radius: 3px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
    color: white(87);
    background-color: black(87);
    pointer-events: none;
    z-index: 900;
  }

  // .ngx-contextmenu {
  //   .dropdown-menu {
  //     box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  //     background-color: white;
  //     padding: 10px 0;
  //     margin: 0;

  //     &:focus {
  //       outline: none;
  //     }
  //   }

  //   li {
  //     display: block;
  //     white-space: nowrap;

  //     &.divider {
  //       border-top: 1px solid #f2f2f2;
  //       margin: 10px 0;
  //     }

  //     a {
  //       display: flex;
  //       flex-direction: row;
  //       box-sizing: border-box;
  //       min-width: 164px;
  //       max-width: 240px;
  //       padding: 8px 16px;
  //       font-size: 16px;
  //       line-height: 24px;
  //       color: #7e7e7d;
  //       text-decoration: none;

  //       &:hover, &:focus {
  //         background-color: #f2f2f2;
  //         outline: none;
  //       }

  //       span {
  //         margin-left: 4px;
  //         margin-right: 12px;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         color: #000000;
  //       }

  //       i {
  //         margin-right: 4px;
  //       }
  //     }
  //   }
  // }

  .mat-input-element[type="time"]::after {
    content: "";
  }

  .no-uppercase {
    text-transform: none;
  }

  .profile-select.mat-select-panel {
    width: auto;
    max-width: max-content;
    min-width: 100% !important; //have to add this because of the inline styles
  }

  .fade-time-input {
    .mat-form-field-label-wrapper,
    .mat-form-field-label {
      overflow: visible;
    }

    &.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      mat-label {
        width: 140%;
      }
    }

    .mat-form-field-label {
      mat-label {
        width: 90%;
        transition-duration: 0.3s;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .help-menu {
    max-width: 350px;
  }

  .nav-side-title-tooptip {
    margin: 0px 0px 7px 0px;
  }
}

@media print {
  html,
  body {
    background: white;
  }

  mat-tooltip-component {
    display: none;
  }
}

@page {
  size: A4 portrait;
  margin: 38pt 38pt 38pt 38pt;
}
