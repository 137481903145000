@import "theme";

.app-global-drop-zone {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: accent();
  opacity: 0.9;
  z-index: 1000;

  font-size: 36px;
  font-weight: 300;
  color: white(87);
}
