$cardBorderRadius: 3px;
$cardPadding: 24px;

$subtleTransition: 150ms cubic-bezier(0.23, 1, 0.32, 1);

/* status colors for screen */
$okScreen: rgb(20, 217, 145);
$warningScreen: rgb(237, 136, 0);
$errorScreen: rgb(226, 53, 28);

/* status colors for print */
$okPrint: #216644;
$warningPrint: #aa6600;
$errorPrint: #b60000;
