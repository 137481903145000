.icon-light-sensor {
  background: url(../assets/images/light-sensor.svg);
  width: 12px;
  height: 18px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-luminaire {
  background: url(../assets/images/luminaire.svg);
  width: 14px;
  height: 18px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-motion {
  background: url(../assets/images/motion-sensor.svg);
  width: 14px;
  height: 14px;
  margin: 2px 0;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-switch {
  background: url(../assets/images/switch.svg);
  width: 14px;
  height: 14px;
  margin: 2px 0;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

[class*="app-icon"] {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.app-icon-light-sensor {
  background-image: url(../assets/images/light-sensor.svg);
}

.app-icon-luminaire {
  background-image: url(../assets/images/luminaire.svg);
}

.app-icon-motion {
  background-image: url(../assets/images/motion-sensor.svg);
}

.app-icon-switch {
  background-image: url(../assets/images/switch.svg);
}
